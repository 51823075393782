<template>
  <div v-if="lang" :class="!online ? 'offline' : null">
    <v-app
      v-if="!loading && (isWebView || !config.disableBrowser)"
      :style="
        '--bg-primary:' +
        userData.theme?.filter((x) => x.Code === 'Theme')[0].Color
      "
    >
      <router-view />
    </v-app>
    <v-app v-else-if="!loading && !isWebView && envCode === 'development'">
      <router-view />
    </v-app>
    <v-app v-else-if="!loading && !isWebView && envCode !== 'development'">
      <v-container class="pt-5 mt-4">
        <h2>Device not supported</h2>
        <p>
          We currently do not support your device to access this page. Please
          use a mobile phone to access the page.
        </p>
      </v-container>
    </v-app>
    <v-app v-else>
      <v-main class="align-center d-flex justify-center flex-column">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-main>
    </v-app>
  </div>
  <popup-model
    v-if="isInvalidPayload && !showError"
    v-model="showInvalidPayloadError"
    :message="payloadError"
    :showButton="true"
    :ok="true"
    :closeIcon="false"
    :persistent="true"
  >
  </popup-model>
  <popup-model
    v-else-if="globalErrorCode != null && $route.name!='login' && $route.name!='register' && opsError==null && !$route.name?.toLowerCase()?.includes('wallet') "
    v-model="showError"
    :message="normalError"
    :logout="true"
    :closeIcon="false"
    :oops="true"
    @logout="clearLogoutError"
    :persistent="true"
  >
  </popup-model>
  <popup-model
    v-else-if="normalError!=null && globalErrorCode == null"
    v-model="showError"
    :message="normalError"
    :ok="true"
    @ok="clearError"
    @close="clearError"
    :persistent="true"
  >
  </popup-model>
  <popup-model
    v-else-if="!normalError?.toLowerCase()?.includes('insufficient') && !normalError?.toLowerCase()?.includes('4000')"
    v-model="showError"
    :message="normalError"
    :ok="true"
    @ok="clearError"
    @close="clearError"
    :persistent="true"
  >
  </popup-model>
  <popup-model
    v-else-if="normalError?.toLowerCase()?.includes('4000')"
    v-model="showError"
    :message="normalError"
    :ok="true"
    @ok="clearError"
    :closeIcon="false"
    :persistent="true"
  >
  </popup-model>
  <popup-model
    v-else-if="normalError?.toLowerCase()?.includes('insufficient wallet')"
    v-model="showError"
    :message="lang.inssufficientBal_message"
    :ok="true"
    :oops="true"
    @ok="clearError"
    @close="closePopup"
    :persistent="true"
  >
    <template v-slot:actions>
      <v-row>
        <v-col cols="12">
          <v-btn
            flat
            aria-label="close"
            style="font-size: 24px;"
            class="secondary-btn"
            :to="{
              name: 'wallettopup',
              params: {
                tag: this.$route.name,
              },
            }"
            @click="closePopup"
          >
            {{ lang.topup_now_btn }}
          </v-btn>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-btn
            aria-label="close"
            style="font-size: 24px;"
            flat
            class="secondary-btn transparent-btn"
            @click="closePopup"
          >
            {{ lang.cancelButton }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </popup-model>
  <popup-model
    v-model="showOpsError"
    :oops="true"
    :message="opsError"
    @ok="clearError"
    @close="closePopup"
    :persistent="true"
    :ok="true"
  >
  </popup-model>
  <popup-model
    v-model="showOffline"
    :oops="true"
    :message="lang?.connectivity_issue_message"
    @close="clearError"
    :persistent="true"
  >
    <template #actions>
      <v-btn  aria-label="check" flat class="secondary-btn" @click="checkInternet">
        {{ lang?.retry }}
      </v-btn>
    </template>
  </popup-model>
  <LoadingOverlay v-model="isLoading" />
  <popup-model
    v-model="maintenanceError"
    :closeIcon="false"
    :imageSrc="this.maintenanceImage"
    :persistent="true"
  >
  <template #actions>
      <v-btn  aria-label="close" flat class="secondary-btn" @click="maintenanceError=false;showExit=true">
        {{ lang?.yes_btn }}
      </v-btn>
    </template>
  </popup-model>
  <popup-model
    v-model="showExit"
    :message="lang?.exit_description"
    @close="showExit = false"
    :persistent="true"
  >
    <template #actions>
      <v-btn  aria-label="close" flat class="secondary-btn" @click="closeWindow">
        {{ lang?.yes_btn }}
      </v-btn>
    </template>
  </popup-model>
  <PopupModel
    :persistent="true"
    v-model="GSHRAlertModal"
    :title="GSHRTitle"
    :message="GSHRMessage"
    :closeIcon="false"
    :bottomSpace="false"
  >
    <template v-slot:actions>
      <div class="gap-width">
        <div class="w-100">
          <v-btn
            flat
            aria-label="okay"
            class="secondary-btn"
            @click="
              $router.push({
                name: tabIconData?.partnerID,
                params: {
                  type: tabIconData?.productID,
                },
                query: {
                  name: tabIconData?.icon_title,
                },
              }),
                (GSHRAlertModal = false)
            "
          >
            {{ lang.oK_btn }}
          </v-btn>
        </div>
        <div class="w-100">
          <v-btn
            aria-label="cancel"
            @click="GSHRAlertModal = false"
            class="underline-borderless-btn mt-2 font-16"
          >
            {{ lang.cancel_btn }}
          </v-btn>
        </div>
      </div>
    </template>
  </PopupModel>
  <PopupModel
    :persistent="true"
    v-model="GSHRModal"
    :title="GSHRTitle"
    :message="GSHRMessage"
    :closeIcon="false"
    :bottomSpace="false"
  >
    <template v-slot:actions>
      <div class="gap-width">
        <div class="w-100">
          <v-btn
            flat
            aria-label="okay"
            class="secondary-btn"
            @click="
              $router.push({
                name: tabIconData?.partnerID,
                params: {
                  type: tabIconData?.productID,
                },
                query: {
                  name: tabIconData?.icon_title,
                },
              }),
                (GSHRModal = false)
            "
          >
            {{ lang.oK_btn }}
          </v-btn>
        </div>
        <div class="w-100">
          <v-btn
            aria-label="cancel"
            @click="GSHRModal = false"
            class="underline-borderless-btn mt-2 font-16"
          >
            {{ lang.cancel_btn }}
          </v-btn>
        </div>
      </div>
    </template>
  </PopupModel>
</template>

<script>
import PopupModel from "./components/Common/PopupModel.vue";
import config from "./config.json";
import "./assets/global.scss";
import LoadingOverlay from "./components/Common/LoadingOverlay.vue";

export default {
  name: "App",
  components: { PopupModel, LoadingOverlay },
  data() {
    return {
      config,
      loading: true,
      showError: false,
      showOpsError: false,
      maintenanceError:false,
      envCode: "",
      online: navigator.onLine,
      onlineDetector: null,
      showOffline: false,
      isLoading: false,
      showExit: false,
      showInvalidPayloadError: false,
      isInvalidPayload: false,
      GSHRTitle: "",
      GSHRModal: false,
      GSHRAlertModal: false,
      tabIconData: null,
      GSHRMessage: "",
      able:true,
      iconKey:'',
      maintenanceImage:'',
      cardEnquiry:[],
    };
  },
  computed: {   
    walletData() {
      return this.$store.state.wallet.wallet;
    },
    lang() {
      return this.$store.state.lang;
    },
    globalErrorCode() {
      return this.$store.state.globalErrorCode;
    },
    payloadError() {
      return this.$store.state.globalPayloadError;
    },
    maintenanceCase() {
      return this.$store.state.maintenanceError;
    },
    normalError() {
      return this.$store.state.globalError;
    },
    opsError() {
      return this.$store.state.globalOpsError;
    },
    loginUser() {
      return this.$store.state.login.loginUser;
    },
    userData() {
      return this.$store.state.userData;
    },
    customGlobalErrorRoute() {
      return this.$store.state.globalData.customGlobalErrorRoute;
    },
    isWebView() {
      if (typeof window === undefined) {
        return false;
      }

      let navigator = window.navigator;
      const standalone = navigator.standalone;
      const userAgent = navigator.userAgent.toLowerCase();
      const safari = /safari/.test(userAgent);
      const ios = /iphone|ipod|ipad/.test(userAgent);

      return ios ? !standalone && !safari : userAgent.includes("wv");
    },
  },
  watch: {
    maintenanceCase(val){
      if(val !=null && val.responseParams && val.responseParams.length>0){
        this.maintenanceImage = val.responseParams[0]
      }
    },
    payloadError(val) {
      if (val != null) {
        this.showInvalidPayloadError = true;
      } else {
        this.showInvalidPayloadError = false;
      }
    },
    normalError(val) {
      if (val != null && !val.includes('4000')) {
        if(val.includes('System Maintenance')){
          this.maintenanceError = true
        }else{
          this.showError = true;
        }
      } else {
        this.showError = false;
      }
    },
    opsError(val) {
      if (val != null) {
        this.showOpsError = true;
      } else {
        this.showOpsError = false;
      }
    },
    online(val) {
      if (!val) {
        this.showOffline = true;
      } else {
        this.showOffline = false;
      }
    },
  },
  methods: {
    redirectProfile(){
      this.showComplete=false
      this.$router.push({
        name:'editProfile'
      })
    },
    getCardEnquiry() {
      return new Promise((resolve) => {
        this.$store.dispatch("wallet/getCardEnquiry").then((result) => {
          if (result.responseCode !== "000000") {
            if(result.responseCode== "503"){
              this.$store.dispatch("setMaintenance", result);
            }
            this.$store.dispatch("setGlobalError", result.responseDescription);
          } else {
            this.cardEnquiry = result.data;
          }
          resolve(result);
        });
      });
    },
    validateLogoutMessage(message) {
      let list_name = ["Your session has ended. Please login again.", "Sesi anda telah tamat. Sila log masuk semula."]
     if(list_name.includes(message)) {
        return true
      }
      else{
        return false
      }
    },
    routeToFinancial(icon){
      this.$router.push({
          name: icon.icon_title=='Telekom Malaysia' ? icon.deeplink : icon.icon_group,
          params: {
            type: icon.icon_key,
          },
        });
    },
    directRoute(item,icon){
      if (
        icon.screen_key === "financial_m1_prepaid_screen" ||
        icon.screen_key === "blc_account_screen" ||
        icon.screen_key === "financial_m1_account_screen"
      ) {
        this.routeToFinancial(icon)
      }  else if (icon.partnerID) {
        this.setSelectedTabData(item.icon_key, icon);
        this.handleGSHRTab(icon)
      } else if (icon.icon_group === "TabFinancialServicesOthers") {
        this.$router.push({
          name: icon.screen_key,
          params: {
            type: icon.screen_key,
          },
        });
      }
      else if(icon.icon_key.includes('TabIslamic')){
        this.$router.push({
              name: "List",
              params: {
                tab:'Islamic',
          },
        });
      }
      else if(icon.icon_key === 'PrepaidTopupIAT' ||
      icon.icon_key === 'ElectricityIntBillPayment'){
          this.handleINT(icon, item.icon_key)
          this.handleFBTagging(icon.icon_key)
      }
    }, 
    findItem(data, key) {
      return data?.find((item) => item.icon_key.toString().toLowerCase() ==
            key.toString().toLowerCase())
    }, 
    searchDirect(data, key) {
      let icon_item = this.findItem(data.items, key);
      if (icon_item) {
        return this.$router.push({
          name: "List",
          params: {
            tab: icon_item.deeplink,
          },
        });
      } else {
        data.items.forEach((item) => {
          let sub_icon_item = this.findItem(item.items, key);
          if (sub_icon_item) {
            return this.directRoute(item, sub_icon_item);
          } else {
            item.items?.forEach((item2) => {
              let sub_icon_item_2 = this.findItem(item2.items, key);
              if (sub_icon_item_2) {
                return this.directRoute(item2, sub_icon_item_2);
              }
            });
          }
        });
      }
    },
    handleFBTagging(key) {
      if(key=="PrepaidTopupIAT"){
        this.logAnalyticsEvents("InternationalAirtimeTopUpBT");
      }
      if(key=="ElectricityIntBillPayment"){
        this.logAnalyticsEvents("ForeignBillPaymentBT");
      }
    },
    handleINT(item, selectedTab) {
      if (localStorage.getItem("IATData")) {
        localStorage.removeItem("IATData");
      }
      if (localStorage.getItem("ForeignBillData")) {
        localStorage.removeItem("ForeignBillData");
      }
      if(item?.icon_key === "ElectricityIntBillPayment") {
        this.handleUnderAgeMessage(18)
      } 
      if(item?.icon_key === "PrepaidTopupIAT") {
        this.handleUnderAgeMessage(12)
      } 
      this.setSelectedTabData(selectedTab, item);
      this.$router.push({
        name: item.icon_key,
        params: {
          type: item.icon_key,
        },
      });
    },
    handleGSHRTabPartner(icon) {
      this.tabIconData = icon;
      switch (icon?.productID) {
        case "GOGOLD":
        case "DIYTourTravel":
        this.handleUnderAgeMessage(18)
        break;
        case "PAYHUB_TRANSPORT":
        case "CatchThatBus":
        case "Youbeli":
        case "Ticket2u":
        case "Tripcarte":
        this.handleUnderAgeMessage(12)
        break
      }
      this.normalGSHR()
      if(this.able){
        this.checkPartnerGSHR();
      }
    },
    handleUnderAgeMessage(age) {
      if (parseInt(this.userData?.age) < age) {
        this.$store.dispatch(
          "setGlobalError",
          this.lang?.restrictedServices18Message
        );
        this.able=false
      }
      else{
        this.able=true
      }
    },
    normalGSHR(){
      this.GSHRTitle = this.lang?.disclaimer_message;
      this.GSHRMessage = this.lang?.GshrRedirect;
    },
    handleGSHRTab(icon) { 
      this.tabIconData = icon; 
      switch (icon?.icon_key ?? icon?.icon_group) {
        case "BillPaymentElectricityMore":
        case "CouncilsMBSJ":
        case "CouncilsMBSA":
        case "CouncilsMBSJMPSPMBJB":
        case "FinancialServicesCollectiusPPPPMARAPTPTN":
        case "PrepaidMoreInternet":
        case "WaterIWKSAPLA":
        case "BillPaymentDigiXOXYES":
          this.handleUnderAgeMessage(12)
          this.normalGSHR()
          break
        case "FinancialServicesChubbPA":
        case "FinancialServicesCurrencyExchange":
        case "FinancialServicesRemittance":
        this.handleUnderAgeMessage(18) 
          this.normalGSHR()
          break;
        case "FinancialServicesChubbMotorcycle":
        this.handleUnderAgeMessage(18)  
          this.GSHRTitle = this.lang?.disclaimer_message;
          this.GSHRMessage = this.lang?.chubb_motor_disclaimer_message;
          break;
        case "FinancialServicesLibertyInsurance":
        this.handleUnderAgeMessage(18)  
          this.GSHRTitle = this.lang?.disclaimer_message;
          this.GSHRMessage = this.lang?.liberty_disclaimer;
          break;
        case "FinancialServicesWealth":
        this.handleUnderAgeMessage(18)  
          this.GSHRTitle = this.lang?.disclaimer_message;
          this.GSHRMessage = this.lang?.wealth_disclaimer;
          break;
        case "TabIslamicServicesZakat":
        case "TabIslamicServicesWakaf":
        case "TabIslamicServicesDerma":
          this.handleUnderAgeMessage(12)  
          break;
        case "TabIslamicServicesFinancialServices":
          this.handleUnderAgeMessage(18)  
          break;
        default:
          this.normalGSHR()
          break;
      } 
      if(this.able){
        this.checkGSHR();
      }
    },
    checkGSHR() {
      this.isLoading = true;
      this.$store
        .dispatch("globalData/getGSHR", this.tabIconData?.productID)
        .then((result) => {
          if(result.Code== "503"){
              this.$store.dispatch("setMaintenance", result);
              return
             }
          else if (result.code !== "200") {
            this.handleGSHRTabPartner(this.tabIconData)
          } else {
            switch (this.tabIconData?.icon_key) {
              case "FinancialServicesChubbMotorcycle":
              case "FinancialServicesLibertyInsurance":
              case "FinancialServicesWealth":
              case "FinancialServicesRemittance":
                this.GSHRModal = true;
                break;
              default:
                this.GSHRAlertModal = true;
                break;
            }
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    loadFalse(){
      setTimeout(() => {
          this.loading = false;
        }, 300);
    },
    checkPartnerGSHR() {
      this.isLoading = true;
      this.$store
        .dispatch("globalData/getGSHR", this.tabIconData?.productID)
        .then((result) => {
          if(result.Code== "503"){
              this.$store.dispatch("setMaintenance", result);
              return
             }
          else if (result.code !== "200") {
            this.handleGSHRTab(this.tabIconData)
          }
           else {
              this.GSHRAlertModal = true;
            }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    setSelectedTabData(selectedTab, icon) {
      const payload = {
        iconKey: icon.icon_key,
        selectedTab: selectedTab,
        icon_title_en: icon.icon_title_en,
      };
      this.$store.commit("favourite/SET_SELECTED_TAB_DATA", payload);
    },
    globalErrorRoute(){
      if (this.$route.name == "invalid") {
        this.$router.replace({ name: 'exit' });
      } else {
        this.$router.push({
          name: this.customGlobalErrorRoute?.name,
          params: { path: this.customGlobalErrorRoute?.params?.path },
        });
      }
      setTimeout(() => {
        const payload = {
          type: "customGlobalErrorRoute",
          data: "",
        };
        this.$store.commit("globalData/setData", payload);
      }, 100);
    },
    clearGlobal(){
      this.clearOtherGlobal()
      this.$store.dispatch("setGlobalOpsError", null);
    },
    clearOtherGlobal(){
      this.$store.commit("setData", {
                type: "globalErrorCode",
                data: null,
              });
      this.$store.dispatch("setMaintenance", null);
      this.$store.dispatch("setGlobalError", null);
      this.$store.dispatch("setGlobalPayloadError", null);
    },
    closePopup() {
      if (this.customGlobalErrorRoute) {
        this.globalErrorRoute()
      }
      setTimeout(() => {
        this.clearGlobal()
      }, 50);
    },
    clearError() {
      this.handleClearGoPIN();
      if (this.customGlobalErrorRoute) {
        this.globalErrorRoute()
      }
      setTimeout(() => {
        this.clearGlobal()
        this.isInvalidPayload = false;
        if (this.$route.name == "invalid") {
          this.$router.replace({ name: 'exit' });
        }
      }, 50);
    },
    backHome(){
      this.$router.push({
              name: "home",
            });
    },
    setLogoutError(result){
    if (result.responseCode !== "000000") {
          this.$store.commit("setData", {
            type: "globalErrorCode",
            data: null,
          });
          this.$store.dispatch(
            "setGlobalError",
            result.responseDescription
          );
        } else {
          this.clearOtherGlobal()
          this.$router.push({ name: "login" });
        }
    },
    clearLogoutError() {
      if (this.$route.name !== "login") {
        this.isLoading = true;
        this.$store
          .dispatch("login/logout")
          .then((result) => {
            if(result.Code== "503"){
              this.$store.dispatch("setMaintenance", result);
              return
             }
            this.setLogoutError(result)
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        setTimeout(() => {
          this.clearOtherGlobal()
        }, 100);
      }
    },
    closeWindow() {
      this.showExit = false;
      this.$router.push({ name: "exit" });
    },
    handleClearGoPIN() {
      this.$store.commit("globalData/setData", {
        type: "clearGoPIN",
        data: true,
      });
      setTimeout(() => {
        this.$store.commit("globalData/setData", {
          type: "clearGoPIN",
          data: false,
        });
      }, 300);
    },
    handleAnnouncement() {
      const payload = {
        type: "announcementStatus",
        data: true,
      };
      this.$store.commit("globalData/setData", payload);
      this.$store.commit("globalData/setData", {
            type: "shownAlerts",
            data: false,
          });
    },
    popstateEventAction() {
      this.$router.beforeEach((to, from, next) => {
        if (
          Object.keys(this.loginUser).length > 1 &&
          this.loginUser.loginSuccess == "Y" &&  from.name
        ) {
          switch (from.name) {
            case 'exit':
              next(false);
              break;
            case 'qrPaymentSuccess': 
            case 'qrPaymentFailed': 
            case 'paymentSuccess': 
            case 'paymentFailed': 
            case 'requestSuccess': 
            case 'sendSuccess': 
              next((to.name && (to.name.includes("home") || to.name.includes("SetupRecurring"))) ? true : false );
              break;
            case 'SetupRecurringSuccess':
              next((to.name && (to.name.includes("home") || to.name == "ManageRecurring")) ? true : false );
              break;
            case 'ManageRecurringSuccess':
              next((to.name && (to.name == "ManageRecurring")) ? true : false );
              break;
            case 'ManageFavouriteSuccess':
              next((to.name && (to.name == "ManageFavourite")) ? true : false );
              break;
            case 'walletaddnewcardsuccess':
              if (this.$route.query.route === "walletaddnewgopayzcard") {
                 next((to.name && to.name.includes("walletsetpin")) ? true : false);
              } 
              else if(this.$route.query.route === "walletaddnewothercard"){
                next(true)
              }
              break;
            case 'home': 
              /* eslint-disable */
              let list_name = ["login", "register", "paymentSuccess", "paymentFailed", "qrPaymentSuccess", "qrPaymentFailed", "requestSuccess", "sendSuccess", "TopupComplete", "SetupRecurringSuccess", undefined]
              if (list_name.includes(to.name)) {
                if ((to.name.includes("login") || to.name.includes("register")) && this.globalErrorCode!=null) {
                  this.showExit = false;
                  next();
                } else if(!(to.name.includes("login") || to.name.includes("register"))) {
                  this.showExit = true;
                  next(false);
                }
              } else {
                next();
              }
              
              break;
            default:
              next();
              break;
          } 
        } else {
          next();
        }
      });
    },
    async checkGlobalError(result){
        if (result.responseCode &&
            (result.responseCode === "400" ||
            result.responseCode.includes("4000"))
          ) {
            this.isInvalidPayload = true;
            this.showInvalidPayloadError = true
            this.setError(result)
            this.checkInvalid(result)
            this.loadFalse()
          }
          else{
            await this.$store.dispatch("fetchSystemParams");
          }
    },
    setError(result){
      this.$store.dispatch("setGlobalPayloadError", result.responseDescription);
            this.$store.dispatch(
              "setGlobalError",
              result.responseDescription
            );
    },
    checkInvalid(result){
      let list_name = ["400001", "400002", "400003", "400004", "400005", "400006", "400022"]
          if (list_name.includes(result.responseCode)) {
              this.$router.push({ name: "invalid" });
          }
     },
    checkSession(result){
      if(!this.userData.isSessionExpired){
          this.backHome()
        } else {
          this.$store.dispatch("login/logout");
          this.$router.push({
            name: result.iconKey,
          });
        } 
    },
    checkUserExist(){
      if (Object.keys(this.loginUser).length > 0) {
            this.backHome()
          } else { 
            this.$router.push({
              name: "login",
            });
          }
    },
    checkPage(result){
          if (result.page) {
            this.checkIconkey(result)
          } 
          else if (result.page == null && ["register", "login"].includes(result.iconKey)) {
            this.iconKey=result.iconKey
            this.checkSession(result)
          } 
          else if (result.responseCode !== "000000") {
            this.checkRegister()
          } else {
            this.checkUserExist()
          }
    },
    checkRegister(){
      if (this.userData.responseCode === "001310") {
          this.$router.push({
            name: "register",
          });
        } else {
          this.$store.dispatch(
          "setGlobalError",
          this.userData.responseDescription
          );
        }
    },
    checkIconkey(result){
      if (Object.keys(this.loginUser).length > 0) {
        if (!result.iconKey) {
        this.$router.push({
          name: result.page,
          });
      } else {
        this.$store.dispatch("globalData/getGetCMS").then((r) => {
          if(r.Code== "503" || r.responseCode == '503'){
              this.$store.dispatch("setMaintenance", result);
              return
          }
          this.searchDirect(r, result.iconKey); 
        }); 
      } 
      }
      else{
        this.$router.push({
              name: "login",
        });
      }
      
    },
    routeInvalid(){
      this.$store.dispatch("setGlobalError", "Invalid Hash");
      this.$router.push({
        name: "invalid",
      });
      this.loadFalse()
    },
    async setAll(objQuery){
      let result = await this.$store.dispatch("initializeAxios", objQuery);
      await this.checkGlobalError(result)
      this.checkPage(result)
    },
    async resetAll(){
      let result = await this.$store.dispatch("initializeAxios", {});
      await this.checkGlobalError(result)
    },
    checkInternet() {
      this.showOffline = false;
      setTimeout(() => {
        this.showOffline = !navigator.onLine;
      }, 300);
    },
  },
  created() {
    window.addEventListener("popstate", this.popstateEventAction());
    if (!this.config.detectWebView) {
      this.envCode = process.env.NODE_ENV;
    }
  },
  beforeUnmount() {
    window.removeEventListener("popstate",this.popstateEventAction());
    clearInterval(this.onlineDetector);
  },
  mounted() {
    this.onlineDetector = setInterval(() => {
      this.online = navigator.onLine;
    }, 1000);

    setTimeout(async () => {
      try {
        let objQuery = {};
        Object.keys(this.$route.query).forEach((e) => {
          objQuery[e.toLowerCase()] = this.$route.query[e];
        });
        /* eslint-disable */
        let route_name_rules = ["TopupComplete", "paymentSuccess", "paymentFailed", "ManageRecurringSuccess", "ManageRecurringFailed", "SetupRecurringSuccess", "ManageFavouriteSuccess"]
        if(Object.keys(objQuery).length > 0 && !route_name_rules.includes(this.$route.name)) {
          this.iconKey = objQuery && objQuery.iconkey
          this.handleAnnouncement();
          await this.setAll(objQuery)
        } else {
          await this.resetAll()
          let routeError = ["exit",'home','invalid',undefined]
          if (!routeError.includes(this.$route.name) && !route_name_rules.includes(this.$route.name)) {
            this.backHome()
          }
        }
        this.loadFalse()
      } catch (error) {
        this.routeInvalid()
      }
    }, 500)
  },
};
</script>
<style>
html {
  font-family: "Poppins", sans-serif !important;
  font-display: swap;
}
.offline {
  filter: grayscale(1);
  pointer-events: none;
}
</style>
<style scoped>
.transparent-btn{
background-color: transparent;
box-shadow: none;
color: gray;
text-transform: capitalize;
text-decoration: underline;
}

</style>
